<template>
    <!-- 摄影订单详情路由 -->
    <div class="order_serve_box">
        <!-- 用户信息标题 -->
        <div class="order_serve_title">用户信息</div>
        <!-- 用户信息 -->
        <div class="order_serve_user_info">
            <span>{{order_detail.userName}}</span>
            <span>{{order_detail.userPhone}}</span>
        </div>
        <!-- 拍摄时间标题 -->
        <div class="order_serve_title">拍摄时间</div>
        <!-- 拍摄时间 -->
        <div class="order_serve_time_box">
            <!-- 开始时间 -->
            <div class="order_serve_time"
                v-if="order_detail.beginTime">
                <!-- 日期 -->
                <div class="order_serve_time_date">{{order_detail.beginTime[1]}}</div>
                <!-- 时间 -->
                <div class="order_serve_time_week">
                    <!-- 周 -->
                    <span>{{order_detail.beginTime[0]}}</span>
                    <!-- 时间 -->
                    <span>{{order_detail.beginTime[1]}}</span>
                </div>
            </div>
            <!-- 分割线 -->
            <hr style="width: 100px;display: inline-block;vertical-align:middle;margin: 20px">
            <!-- 结束时间 -->
            <div class="order_serve_time"
                v-if="order_detail.endTime">
                <!-- 日期 -->
                <div class="order_serve_time_date">{{order_detail.endTime[1]}}</div>
                <!-- 时间 -->
                <div class="order_serve_time_week">
                    <!-- 周 -->
                    <span>{{order_detail.endTime[0]}}</span>
                    <!-- 时间 -->
                    <span>{{order_detail.endTime[1]}}</span>
                </div>
            </div>
        </div>
        <!-- 订单详情标题 -->
        <div class="order_serve_title">订单详情</div>
        <!-- 订单详情 -->
        <div class="order_serve_detail_box">
            <order-check-lease
                v-if="$route.name == 'clothesPay'">
            </order-check-lease>
            <order-check-photograph
                v-if="$route.name == 'photographPay'">
            </order-check-photograph>
            <order-check-field
                v-if="$route.name == 'fieldPay'">
            </order-check-field>
        </div>
        <!-- 结算 -->
        <div class="order_serve_total_box">
            <!-- 标题 -->
            <span>订单总额：</span>
            <!-- 价格 -->
            <span>{{"¥"+order_detail.all_price}}</span>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: mapState({
        order_detail: state => state.order.order_detail
    }),
    components: {
        orderCheckLease: () => import("@/components/orderCheckLease"),
		orderLogistics : () => import("@/components/orderLogistics "),
        orderCheckPhotograph: () => import("@/components/orderCheckPhotograph"),
        orderCheckField: () => import("@/components/orderCheckField")
    },
}
</script>

<style scoped>
/* element-ui */
.order_serve_detail_box>>>.el-row>.el-col{
    margin-bottom: 10px;
}

/* 租赁订单盒子 */
.order_serve_box{
    border: 1px solid #C0C4CC;
}
/* 标题 */
.order_serve_title{
    padding: 15px 20px;
    font-size: 17px;
    background-color: #E4E7ED;
}

/* 用户信息 */
.order_serve_user_info{
    padding: 15px 20px;
}
.order_serve_user_info>span + span{
    margin-left: 10px;
}

/* 时间盒子 */
.order_serve_time_box{
    padding: 15px 20px;
}
/* 时间 */
.order_serve_time{
    display: inline-block;
    vertical-align: middle;
}
/* 日期 */
.order_serve_time_date{
    font-size: 25px;
    font-weight: bold;
}
/* 星期 */
.order_serve_time_week{
    margin-top: 5px;
}
.order_serve_time_week>span + span{
    margin-left: 10px;
}

/* 订单详情 */
.order_serve_detail_box{
    padding: 15px 20px;
}
/* 订单详情右侧 */
.order_serve_detail_rt{
    text-align: right;
}
/* 价格颜色 */
.order_serve_detail_rt_price{
    margin-left: 10px;
    font-size: 17px;
    font-weight: bold;
    color: #F56C6C;
}

/* 结算 */
.order_serve_total_box{
    background-color: #C0C4CC;
    padding: 15px 20px;
    text-align: right;
}
.order_serve_total_box>span + span{
    margin-left: 15px;
    font-size: 25px;
    font-weight: bold;
    color: red;
}
</style>
